/* Browser defaults resets */

abbr[title] {
  text-decoration: none;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

tr.materiel-reserve {
  background-color: #0070c0;
  color: #efefef;
}

tr.materiel-na {
  background-color: #7030a0;
  color: #efefef;
}

.ng-table {
  margin-bottom: 0;
}

.ng-table-pagination {
  margin-left: 20px;
}

.ng-table-group-header {
  display: none !important;
  visibility: hidden !important;
}

/* ====================================================== */
.fixed .main-header,
.fixed .main-sidebar,
.fixed .left-side {
  position: fixed;
}

.checkboxb label {
  padding-left: 0px;
}

/*
.checkbox label, .radio label {
  padding-left: 0px;
}
*/

#loading-bar .bar {
  background: #222d32;
  height: 3px;
  margin-top: 50px;
  position: absolute;
}

dd {
  margin-left: 5px;
}

.radiob {
  display: inline-flex;
  margin-left: 10px;
  align-content: left;
  margin-top: 1px;
  margin-bottom: 10px;
}

.checkboxb label:after,
.radiob label:after {
  content: "";
  display: table;
  clear: both;
  display: inline-block;
}

.checkboxb .cr,
.radiob .cr {
  position: relative;
  border: 1px solid #a9a9a9;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
  vertical-align: middle;
}

.radiob .cr {
  border-radius: 50%;
}

.checkboxb .cr .cr-icon,
.radiob .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 20%;
}

.radiob .cr .cr-icon {
  margin-left: 0.04em;
}

.checkboxb label input[type="checkbox"],
.radiob label input[type="radio"] {
  display: none;
}

.checkboxb label input[type="checkbox"] + .cr > .cr-icon,
.radiob label input[type="radio"] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkboxb label input[type="checkbox"]:checked + .cr > .cr-icon,
.radiob label input[type="radio"]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkboxb label input[type="checkbox"]:disabled + .cr,
.radiob label input[type="radio"]:disabled + .cr {
  opacity: 0.5;
}

dd {
  margin-left: 5px;
}
.my-drop-zone {
  border: dotted 3px lightgray;
}
.nv-file-over {
  border: dotted 3px red;
} /* Default class applied to drop zones on over */
.another-file-over-class {
  border: dotted 3px green;
}
html,
body {
  height: 100%;
}

/*Signature*/
.wrapper2 {
  position: relative;
  width: 400px;
  height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signature-pad-container {
  position: relative;
  padding-top: 50%;
}

.signature-pad {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}

.btn-clear-signature {
  margin-top: 4rem;
}

.hover:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

/* Bootstrap Fieldsets */
fieldset {
  margin-top: 1.5rem;
}

/* ================================ */
/*     Formulaires touch-friendly   */
/* ================================ */

.radio-touch {
  font-size: 28px;
}

.radio-touch input[type="radio"] {
  margin-top: 14px;
}

/* ================================ */
/*     Boxes                        */
/* ================================ */

.box-header .box-title {
  font-size: 22px;
}

/* ================================ */
/*     Page de connexion            */
/* ================================ */

.entreprise-logo {
  height: 200px;
  width: auto;
  margin: 0 auto 2rem;
}

.login-box {
  margin-top: 4%;
}

/* ================================ */
/*     Links Utils                  */
/* ================================ */

.link-block {
  display: block;
}

/* ================================ */
/*     Attestations & délivrances   */
/* ================================ */

.delivrance-blank-state {
  text-align: center;
  padding-bottom: 1rem;
}

/* ================================ */
/*     Ui Router                    */
/* ================================ */

[ui-sref]:hover {
  cursor: pointer;
}

.nav-dossier-amiante > li > a {
  border-bottom-color: #aaa !important;
}

.nav-dossier-amiante > li > a:hover,
.nav-dossier-amiante > li > a:focus {
  font-weight: bold !important;
}

.nav-dossier-amiante > .active > a {
  background-color: transparent !important;
  border-width: 0 0 1px 0 !important;
  font-weight: bold !important;
  border-bottom: 3px solid #3c8dbc !important;
}
